@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
html {
  overflow-y: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

h1,
h2,
h3 {
  margin: 0 !important;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 8px;
}

.custom-gradient-dashboard-1 {
  background: linear-gradient(177.23deg, rgba(156, 44, 243, 0.8) -13.49%, rgba(58, 111, 249, 0.8) 109.75%);
}

.custom-gradient-dashboard-2 {
  background: linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%);
}
.custom-gradient-dashboard-3 {
  background: linear-gradient(81.62deg, #26ccae 2.25%, #214947 79.87%);
}
.ant-menu-submenu-arrow:before,
.ant-menu-submenu-arrow:after {
  color: white;
}

.custom-scroll-bar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #274472;
}

.ant-menu-inline-collapsed-tooltip {
  color: white !important;
}
.ant-menu-title-content {
  color: white !;
}
li.ant-menu-item-selected {
  border-radius: 0% !important;
  background-color: #ffffff !important;
  border-right: 3.5px solid #10e9e9;
  margin-right: 0px !important;
  width: calc(100% - 4px) !important;
}

li.ant-menu-item-selected * {
  color: black !important;
}
/* div.ant-menu-submenu-title {
  padding-right: 16px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}
li.ant-menu-submenu.ant-menu-submenu-inline {
  margin-bottom: 7px;
}

/* .ant-layout-sider-children li.ant-menu-item {
  padding-left: 21px !important;
} */

/* .ant-layout-sider-collapsed li.ant-menu-item {
  padding-left: 25px !important;
}
.ant-menu-item.ant-menu-item-only-child {
  padding-left: 45px !important;
}
.ant-menu-submenu-popup .ant-menu-item {
  padding-left: 10px !important;
}

.ant-menu-item-active {
  color: white !important;
}
.ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item.ant-menu-item-only-child {
  padding-left: 15px !important;
} */

/* .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-item {
  padding-left: 65px !important;
} */

.region-selector .ant-select-selector {
  background-color: #002626 !important;
  border: none !important;
  color: white !important;
}
.no-translate {
  unicode-bidi: embed;
}
.no-translate {
  translate: none !important;
}
.ant-drawer-body {
  padding: 0 !important;
}
.ant-divider-horizontal {
  margin: 0 !important;
}

.pagination__input > .ant-input-number-handler-wrap {
  display: none !important;
}

.ant-table-expanded-row.ant-table-expanded-row-level-1 {
  background: #0781811a;
  padding: 16px !important;
}

@import './styles/header.css';
/* @import './styles/utility.css'; */
@tailwind components;
@tailwind utilities;

/* Tailwind utilities */
.border-b-solid {
  border-bottom-style: solid;
}
.border-t-solid {
  border-top-style: solid;
}

.border-l-solid {
  border-left-style: solid;
}

.border-r-solid {
  border-right-style: solid;
}

.powered-by {
  display: none !important;
}

.custom-scroll-bar-x::-webkit-scrollbar {
  height: 8px;
}

.custom-scroll-bar-x::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custom-scroll-bar-x::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #274472;
}

.introduction_back {
  background-image: url(../src/assets/images/CBT_Background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.ant-input,
.ant-input-password,
.ant-select-selector,
.ant-input-affix-wrapper {
  border-radius: 0 !important;
}

.ant-btn {
  border-radius: 0 !important;
}

.ant-menu-light.ant-menu-submenu-popup > .ant-menu {
  background-color: #0b3d3d;
}
.country-details * {
  border: none;
}

.nebula-config .ant-card-body {
  padding: 0;
}
#main-content {
  padding-top: 0px !important;
}

.anticon.anticon-down.ant-select-suffix {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,%3Csvg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0.109486 0.495312L4.75693 5.88431C4.88996 6.03856 5.1461 6.03856 5.28055 5.88431L9.92799 0.495312C10.1006 0.294356 9.94497 0 9.66618 0L0.371294 0C0.0925039 0 -0.0631657 0.294357 0.109486 0.495312Z" fill="%238C8C8C"/%3E%3C/svg%3E');
}

.search-user .ant-form-item-row {
  display: grid !important;
  gap: 20px;
  grid-template-columns: 78px auto;
  align-items: center;
}
.search-user .ant-form-item-label {
  padding: 0px !important;
}
.login-language-dropdown .ant-select-selection-item {
  color: black !important;
}

@media (width>= 1100px) {
  .search-user {
    display: grid !important;
    gap: 20px;
    grid-template-columns: 1fr 1fr 150px;
    align-items: center;
  }
  .search-user > * {
    display: flex;
    align-items: center;
  }
  .search-user .ant-form-item {
    margin-bottom: 0px !important;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

@media (width <= 600px) {
  .mobile-display {
    display: block;
  }
  .tab-display {
    display: none !important;
  }
  .web-display {
    display: none !important;
  }
}

@media (600px < width <= 821px) {
  .tab-display {
    display: block;
  }
  .mobile-display {
    display: none !important;
  }
  .web-display {
    display: none !important;
  }
}

@media (821px < width) {
  .web-display {
    display: block;
  }
  .mobile-display {
    display: none !important;
  }
  .tab-display {
    display: none !important;
  }
}
.ant-input-number-input {
  border-radius: 0px !important;
}
@media (width > 821px) {
  .mobile_tab_display {
    display: none !important;
  }
}

:where(.css-dev-only-do-not-override-2ua9kd).ant-menu .ant-menu-item,
:where(.css-dev-only-do-not-override-2ua9kd).ant-menu .ant-menu-submenu,
:where(.css-dev-only-do-not-override-2ua9kd).ant-menu .ant-menu-submenu-title {
  border-radius: 0px;
}

/* ------------------------------------------------------------ */

/* this is for capitlize first letter when translated to spanish in  datepicker */
.ant-picker-header-view,
.ant-picker-content th {
  text-transform: capitalize;
}

.ant-picker-header-view .ant-picker-month-btn {
  text-transform: capitalize;
}

.ant-picker-content .ant-picker-cell-inner {
  text-transform: capitalize;
}
/* ---------------------------------------------------------------------- */
